<header>
  <!-- start navigation -->
  <nav class="navbar navbar-default navbar-fixed-top bootsnav background-white header-light navbar-expand-lg">
    <div class="container nav-header-container">
      <!-- start logo -->
      <div class="col-auto pl-lg-0">
        <a routerLink="/" title="Pearl">
          <img src="assets/images/logo/logo-black.png" data-rjs="assets/images/logo@2x.png" class="logo-dark default"
            alt="Pearl Index">
        </a>
      </div>
      <!-- end logo -->
      <div class="col accordion-menu pr-0 pr-md-1">
        <button type="button" class="navbar-toggler collapsed" data-toggle="collapse"
          data-target="#navbar-collapse-toggle-1">
          <span class="sr-only">toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <div class="navbar-collapse collapse justify-content-end" id="navbar-collapse-toggle-1">
          <ul id="accordion" class="nav navbar-nav navbar-left no-margin alt-font text-normal" data-in="fadeIn"
            data-out="fadeOut">
            <!-- start menu item -->
            <li>
              <a routerLink="/" routerLinkActive="router-link-active">Home</a>
            </li>
            <!-- end menu item -->
            <li>
              <a routerLink="/about">About</a>
            </li>
            <li>
              <a routerLink="/business-sector">Business Sector</a>
            </li>
            <li class="dropdown megamenu-fw">
              <a (click)="nav('/services','')">Services</a><i class="fas fa-angle-down dropdown-toggle"
                data-toggle="dropdown" aria-hidden="true"></i>
              <div class="menu-back-div dropdown-menu megamenu-content mega-menu collapse mega-menu-full icon-list-menu"
                id="service-menu">
                <ul>
                  <li class="mega-menu-column col-lg-3 d-none d-lg-block">
                    <ul>
                      <li>
                        <img
                          class="service-img menu-banner-image top-10px-padding bottom-10px-padding left-10px-padding right-20px-padding"
                          src="assets/images/header/menu-serv-1.webp" alt="portfolio" />
                      </li>
                      <li>
                        <a (click)="nav('/iservice',{id:1})">Project
                          Management</a>
                      </li>
                      <li><a (click)="nav('/iservice',{id:2})">Manpower</a>
                      </li>
                      <li><a (click)="nav('/iservice',{id:3})">Contracting
                          Supports</a>
                      </li>
                      <li><a (click)="nav('/iservice',{id:4})">Rigging &
                          Crane service</a>
                      </li>
                      <li><a (click)="nav('/iservice',{id:5})">Trading &
                          supply</a>
                      </li>

                    </ul>
                  </li>
                  <li class="mega-menu-column col-lg-3">
                    <ul>
                      <li><a (click)="nav('/iservice',{id:6})">Plant
                          maintenance & turnover</a>
                      </li>
                      <li><a (click)="nav('/iservice',{id:7})">Industrial
                          security system</a>
                      </li>
                      <li><a (click)="nav('/iservice',{id:8})">Engineering,
                          procurement,
                          construction service </a>
                      </li>
                      <li><a (click)="nav('/iservice',{id:9})">Process
                          engineering</a>
                      </li>
                      <li>
                        <img
                          class="service-img header-img-bottom menu-banner-image top-10px-padding bottom-10px-padding left-20px-padding right-20px-padding"
                          src="assets/images/header/menu-serv-2.webp" alt="oil-refinery" />
                      </li>
                    </ul>
                  </li>
                  <li class="mega-menu-column col-lg-3">
                    <ul>
                      <li>
                        <img class="menu-banner-image bottom-10px-padding left-20px-padding right-20px-padding"
                          src="assets/images/header/menu-serv-3.webp" alt="portfolio" />
                      </li>

                      <li><a (click)="nav('/iservice',{id:10})">Mechanical /
                          Piping engineering
                        </a>
                      </li>
                      <li><a (click)="nav('/iservice',{id:11})">QA &
                          QC</a></li>
                      <li><a (click)="nav('/iservice',{id:12})">Document
                          control</a></li>
                      <li><a (click)="nav('/iservice',{id:13})">Logistics
                          support services</a>
                      </li>
                      <li><a (click)="nav('/iservice',{id:14})">Fabrication &
                          Machining </a>
                      </li>
                    </ul>
                  </li>
                  <li class="mega-menu-column col-lg-3">
                    <ul>
                      <li><a (click)="nav('/iservice',{id:15})">Instrumentation
                          & controls </a>
                      </li>
                      <li><a (click)="nav('/iservice',{id:16})">Specialised
                          services </a></li>
                      <li><a (click)="nav('/iservice',{id:17})">Heavy
                          Engineering </a></li>
                      <li><a (click)="nav('/iservice',{id:18})">Operational &
                          maintenance </a>
                      </li>
                      <li><a (click)="nav('/iservice',{id:19})">E &
                          I Maintenance & Operation </a>
                      </li>
                      <li><a (click)="nav('/iservice',{id:20})">Onsite
                          consultancy services </a>
                      </li>
                      <li>
                        <img
                          class="service-img header-img-bottom menu-banner-image top-10px-padding bottom-10px-padding left-20px-padding right-20px-padding"
                          src="assets/images/header/menu-serv-4.webp" alt="oil-rig" />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li class="dropdown megamenu-fw">
              <a href="#" class="text-white-2 disabled">Insights</a>
              <i class="fas fa-angle-down dropdown-toggle" data-toggle="dropdown" aria-hidden="true"></i>
              <!-- start sub menu -->
              <div class="menu-back-div dropdown-menu megamenu-content mega-menu collapse mega-menu-full"
                id="insights-menu">
                <ul>
                  <!-- start sub menu column  -->
                  <li class="mega-menu-column col-lg-4 d-none d-lg-block">
                    <ul>
                      <li>
                        <img class="menu-banner-image padding-10px-lr" src="assets/images/header/menu-in-1.webp"
                          alt="portfolio" />
                      </li>
                    </ul>
                  </li>

                  <li class="mega-menu-column col-lg-4 d-none d-lg-block">
                    <ul>
                      <li>
                        <img class="menu-banner-image padding-10px-lr" src="assets/images/header/menu-in-2.webp"
                          alt="portfolio" />
                      </li>
                    </ul>
                  </li>
                  <li class="mega-menu-column col-lg-4">
                    <!-- start sub menu item  -->
                    <ul class="align-items-center">
                      <li><a (click)="nav2('/insights/divisions','')">Our Divisions</a></li>
                      <li><a (click)="nav2('/insights/ethics','')">Our Ethics</a></li>
                      <li><a (click)="nav2('/insights/clients','')">Our Clients</a></li>
                      <li><a (click)="nav2('/insights/projects','')">Projects</a></li>
                      <li><a (click)="nav2('/insights/qhse','')">QHSE</a></li>
                      <li><a (click)="nav2('/insights/careers','')">Careers</a></li>
                      <li><a (click)="nav2('/insights/media','')">Media & Resources</a></li>
                      <li><a (click)="nav2('/insights/news-and-articles','')">News & Articles</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a routerLink="/contact" routerLinkActive="router-link-active">Contact</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-auto pr-lg-0">
        <div class="header-social-icon d-none d-md-inline-block">
          <a href="https://www.facebook.com/Pearl-Index-110381561194174" title="Facebook" target="_blank"><i
              class="fab fa-facebook-f" aria-hidden="true"></i></a>
          <a href="https://twitter.com/pearlindex" title="Twitter" target="_blank"><i class="fab fa-twitter"></i></a>
          <a href="https://www.linkedin.com/company/pearl-index/" title="LinkedIn" target="_blank"><i
              class="fab fa-linkedin-in"></i></a>
          <a href="https://www.instagram.com/pearl_index/" title="Instagram" target="_blank"><i
              class="fab fa-instagram"></i></a>
        </div>
      </div>
    </div>
  </nav>
  <!-- end navigation -->
</header>
