// const IP = 'https://p-index.com:2525';
const IP = 'https://webapis.p-index.com';
// const IP = 'https://testapi.p-index.com';

// export const environment = {
//   production: true,
//   pageApi: `${IP}/get-page-info`,
//   clientApi: `${IP}/get-clients`,
//   serviceApi: `${IP}/get-services`,
//   iServiceApi: `${IP}/get-service`,
// };

export const environment = {
  production: true,
  pageApi: `${IP}/website/get_page_info.php`,
  clientApi: `${IP}/website/get_clients.php`,
  serviceApi: `${IP}/website/get_services_limitby.php`,
  iServiceApi: `${IP}/website/get_services.php`,
};