import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageInfoService {

  constructor(private _http: HttpClient) { }

  // getPageInfo(page: string) {
  //   return this._http.get(`${environment.pageApi}/${page}`).toPromise();
  // }

  getPageInfo(page: string) {
    return this._http.get(`${environment.pageApi}?page=${page}`).toPromise();
  }
}
