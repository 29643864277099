<div class="footer-standard-dark bg-extra-dark-gray">
  <div class="footer-widget-area padding-five-tb sm-padding-30px-tb">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-3 col-md-6 widget border-right border-color-medium-dark-gray md-no-border-right md-margin-30px-bottom sm-text-center">
          <!-- start logo -->
          <a routerLink="/">
            <!-- <b style="color: white;font-size: larger;">PEARL INDEX</b> -->
            <img class="logo-size width-200" src="assets/images/logo/logo-white.png"
              data-rjs="assets/images/logo/logo.png" alt="Pearl Index">
          </a>
          <br>
          <!-- end logo -->
          <p class="text-small width-95 sm-width-100 text-light-gray">Pearl Index is a private
            company categorized as Multi service providers which includes trading, contracting and various other
            services</p>
          <!-- start social media -->
          <div class="social-icon-style-8 d-inline-block vertical-align-middle">
            <ul class="small-icon no-margin-bottom">
              <li><a class="facebook text-white-2" href="https://www.facebook.com/Pearl-Index-110381561194174"
                  target="_blank"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></li>
              <li><a class="twitter text-white-2" href="https://twitter.com/pearlindex" target="_blank"><i
                    class="fab fa-twitter"></i></a></li>
              <li><a class="linkedin text-white-2" href="https://www.linkedin.com/company/pearl-index"
                  target="_blank"><i class="fab fa-linkedin"></i></a></li>
              <li><a class="instagram text-white-2" href="https://www.instagram.com/pearl_index/" target="_blank"><i
                    class="fab fa-instagram no-margin-right" aria-hidden="true"></i></a></li>
            </ul>
          </div>
          <!-- end social media -->
        </div>
        <!-- start additional links -->
        <div
          class="col-lg-3 col-md-6 widget border-right border-color-medium-dark-gray padding-45px-left md-padding-15px-left md-no-border-right md-margin-30px-bottom text-center text-md-left">
          <div class="widget-title alt-font text-small text-white text-uppercase margin-10px-bottom font-weight-600">
            SERVICES</div>
          <ul class="list-unstyled">
            <li><a class="text-small text-light-gray" [routerLink]="['/iservice']" [queryParams]="{id:1}">Project Management</a></li>
            <li><a class="text-small text-light-gray" [routerLink]="['/iservice']" [queryParams]="{id:2}">Manpower</a></li>
            <li><a class="text-small text-light-gray" [routerLink]="['/iservice']" [queryParams]="{id:3}">Contracting Support</a></li>
            <li><a class="text-small text-light-gray" [routerLink]="['/iservice']" [queryParams]="{id:4}">Rigging & Crane</a></li>
            <li><a class="text-small text-light-gray" [routerLink]="['/iservice']" [queryParams]="{id:5}">Trading & Supply</a></li>
            <li><a class="text-small text-light-gray" [routerLink]="['/iservice']" [queryParams]="{id:6}">Plant Maintenance</a></li>
          </ul>
        </div>
        <!-- end additional links -->
        <!-- start contact information -->
        <div
          class="col-lg-3 col-md-6 widget border-right border-color-medium-dark-gray padding-45px-left md-padding-15px-left md-no-border-right md-margin-30px-bottom text-center text-md-left">
          <div class="widget-title alt-font text-small text-white text-uppercase margin-10px-bottom font-weight-600">
            INSIGHTS</div>
          <ul class="list-unstyled">
            <li><a class="text-small text-light-gray" routerLink="/insights/divisions">Our Divisions</a></li>
            <li><a class="text-small text-light-gray" routerLink="/insights/ethics">Our Ethics</a></li>
            <li><a class="text-small text-light-gray" routerLink="/insights/clients">Our Clients</a></li>
            <li><a class="text-small text-light-gray" routerLink="/insights/projects">Our Projects</a></li>
            <li><a class="text-small text-light-gray" routerLink="/insights/careers">Careers</a></li>
            <li><a class="text-small text-light-gray" routerLink="/insights/news-and-articles">News & Articles</a></li>
          </ul>
        </div>
        <!-- end contact information -->
        <!-- start instagram -->
        <div
          class="col-lg-3 col-md-6 widget padding-45px-left md-padding-15px-left md-clear-both md-no-border-right sm-margin-30px-bottom text-center text-md-left">
          <div class="widget-title alt-font text-small text-white text-uppercase margin-10px-bottom font-weight-600">
            Contact Info</div>
          <p class="text-small d-block margin-15px-bottom width-80 sm-width-100 text-light-gray">Pearl Index<br>
            PO Box 1841, Jabal Street, Al Jubail, KINGDOM OF SAUDI ARABIA</p>
          <div class="text-small text-light-gray">Email: <a class="text-light-gray"
              href="mailto:sales@p-index.com">sales@p-index.com</a></div>
          <div class="text-small text-light-gray">Phone: + 966 13361 2882</div>
          <a routerLink="/contact" class="text-small text-uppercase text-decoration-underline text-light-gray">View
            Direction</a>
        </div>
        <!-- end instagram -->
      </div>
    </div>
  </div>
  <div class="bg-light-blue padding-50px-tb text-center sm-padding-30px-tb">
    <div class="container">
      <div class="row text-light-gray">
        <!-- start copyright -->
        <div class="col-md-6 text-md-left text-small text-center">&copy; 2021 PEARL INDEX</div>
        <div class="col-md-6 text-md-right text-small text-center">
          <a routerLink="terms-and-conditions" class="text-light-gray">Term and
            Condition</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a routerLink="privacy-policy" class="text-light-gray">Privacy
            Policy</a>
        </div>
        <!-- end copyright -->
      </div>
    </div>
  </div>
</div>
