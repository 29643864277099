import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  nav(route: string, params: any) {
    const element = (<HTMLElement>document.getElementById('service-menu'));
    this.router.navigate([route], { queryParams: params })
    element.style.display = 'none';
  }
  nav2(route: string, params: any) {
    const element = (<HTMLElement>document.getElementById('insights-menu'));
    this.router.navigate([route], { queryParams: params })
    element.style.display = 'none';
  }
}
