import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: { breadcrumb: 'Home' }
  },
  {
    path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
    data: { breadcrumb: 'About Us' }
  },
  {
    path: 'business-sector', loadChildren: () => import('./business-sector/business-sector.module').then(m => m.BusinessSectorModule),
    data: { breadcrumb: 'Business Sector' }
  },
  {
    path: 'insights', loadChildren: () => import('./insights/insights.module').then(m => m.InsightsModule),
    data: { preload: false }
  },
  {
    path: 'services', loadChildren: () => import('./services/services.module').then(m => m.ServicesModule),
    data: { preload: false, breadcrumb: 'Services' }
  },
  {
    path: 'iservice', loadChildren: () => import('./individual-services/individual-services.module').then(m => m.IndividualServicesModule),
    data: { breadcrumb: 'Services' }
  },
  {
    path: 'contact', loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule),
    data: { breadcrumb: 'Contact Us' }
  },
  {
    path: 'privacy-policy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    data: { breadcrumb: 'Privacy policy' }
  },
  {
    path: 'terms-and-conditions', loadChildren: () => import('./terms-condition/terms-condition.module').then(m => m.TermsConditionModule),
    data: { breadcrumb: 'Terms & Conditions' }
  },
  {
    path: '**', pathMatch: 'full', component: NotFoundComponent,
    data: { breadcrumb: '404 | Page Not found!' }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      { preloadingStrategy: QuicklinkStrategy, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
