<section id="home" class="p-0 parallax mobile-height wow fadeIn" data-stellar-background-ratio="0.5"
  style="background-image:url('assets/images/parallax-bg31.jpg');">
  <div class="opacity-full bg-extra-dark-gray"></div>
  <div class="container position-relative full-screen">
    <div class="slider-typography text-center">
      <div class="slider-text-middle-main">
        <div class="slider-text-middle">
          <div class="bg-black-opacity-light w-50 mx-auto md-width-80">
            <div class="padding-fifteen-all sm-padding-20px-all">
              <span
                class="title-extra-large text-white-2 font-weight-600 d-block margin-30px-bottom sm-margin-10px-bottom">404!</span>
              <h6 class="text-uppercase text-deep-pink font-weight-600 alt-font d-block margin-5px-bottom">Page
                Not Found</h6>
              <span class="text-medium-gray width-60 d-block mx-auto text-large md-width-100">The page you were
                looking for could not be found.</span>
              <br>
              <a href="index.html" class="btn btn-transparent-white btn-medium text-extra-small border-radius-4"><i
                  class="ti-arrow-left margin-5px-right ml-0" aria-hidden="true"></i> Back To Homepage</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
